a, button {
  background: none;
  border: none;
  border-radius: 0;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  &:focus {
    outline: none;
  }
}

.question-wrapper {
  .btn {
    @extend %flexbox;
    width: 100%;
    span {
      @include flex(1 0 auto);
    }
    &.btn-primary {
      color: #333;
      background-color: #fff;
      border-color: #333;
      height: 100%;
      &.selected {
        color: #fff;
        background-color: $primary;
        border-color: $primary;
      }
    }
  }
}

.downloadButton {
  .btn {
    &.btn-primary {
      font-weight: bold;
      text-transform: uppercase;
      color: #ffffff;
      background-color: $secondary;
      border: 0;
      width: auto;
    }
  }
}
