.error {
  order: 1;
  font-size: 14px;
  color: #f53242;
  .error-message {
    padding: 4px 6px 0;
    text-align: center;
  }
  &.error-date {
    font-size: 13px;
    padding: 3px 5px 0;
  }
  + .field {
    input {
      border-color: #f53242;
      color: #f53242;
      background-color: rgba(#f53242, .1);
    }
  }
}

.inline {
  .error-date {
    @extend %flexbox;
    @include align-items(center);
    height: 100%;
  }
}

.question-wrapper .question-wrapper {
  .justify-content-center {
    .error {
      .error-message {
        text-align: center;
      }
    }
  }
  .error {
    .error-message {
      text-align: left;
    }
  }
}
